import { ref, shallowRef, set, nextTick } from 'vue'
import schttp from 'public/src/services/schttp'
import apiReport from 'public/src/pages/common/apiReport'
import { mergeQueryString } from '@shein/common-function'
import { postTokenUnPayComplete } from '@/public/src/pages/common/oneClickPay/fetcher.js'
import kafkaReport from '@/public/src/pages/common/handlePay/kafkaReport.js'
import { debuggerLog, getDeviceInfo, getProductType, getCheckoutAgainUrl, makeForm } from '@/public/src/pages/common/handlePay/utils.js'
import useGetPubKey from '@/public/src/pages/common/handlePay/hooks/useGetPubKey.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import encryptParams from '@/public/src/pages/components/prePay/utils/encryptParams.js'
import { nextIdle } from '@/public/src/pages/components/prePay/helpers.js'
import { useAlipayCardThreeDs } from 'public/src/pages/common/handlePay/hooks/useAlipayCardThreeDs.js'

// import ppcJsonMock from '../__mock__/ppc.json'
// import ppcrJsonMock from '../__mock__/ppcr.json'

const noop = function () {}

export default ({
  scene = '',
  challengeModalOptions = { adyenChallengeShow: false, wpChallengeShow: false }
} = {}) => {
  debuggerLog('useTokenPay====', scene)
  // let initPciPromise = null
  const unifiedPayChallengeInfo = ref({
    gatewayPayNo: '',
    paymentCode: '',
    independThreedTraceID: ''
  })
  const ddcInfo = ref({
    formActionUrl: '',
    jwt: ''
  })
  const tokenList = ref([])
  const selectedTokenPayInfo = ref({
    id: '',
    card_bin: '',
    last_four_no: ''
  })
  const tokenPayParams = ref({
    billno: '',
    payment_method: '',
    id: '',
    card_bin: '',
    last_four_no: '',
    countryCode: ''
  })

  const tokenPayConfig = shallowRef({
    checkoutType: '',
    toggleLoadingStatus: () => {},
    onPayComplete: noop
    // wpChallengeModalClassName: 'token-pay-challenge__iframe',
  })
  const necessaryOrderInfo = ref({
    billno: '',
    sub_billnos: [],
    totalPrice: {}
  })

  // const prePayForCheckoutStatus = reactive({

  // })

  const adyenInstance = shallowRef(null)
  const ppCardInstance = shallowRef(null)

  const { getPubKey } = useGetPubKey()

  async function initPrePayForCheckoutConfig ({
    checkoutType = '',
    toggleLoadingStatus
  } = {}) {

    tokenPayConfig.value.checkoutType = checkoutType || ''
    tokenPayConfig.value.toggleLoadingStatus = toggleLoadingStatus || function () {}
    await nextIdle()
    import('public/src/pages/common/handlePay/channel/adyen.js').then(async ({ default: Adyen3ds }) => {
      const adyenKeyInfo = await schttp({
        url: '/ltspc/api/pay/adyenKey/get'
      })
      adyenInstance.value = new Adyen3ds({
        completeHandle: _handleThreeDSComplete,
        errorHandle: _handleThreeDSError,
        originKey: adyenKeyInfo?.info?.key || ''
      })
    })
    import('public/src/pages/common/handlePay/channel/pp-card.js').then(({ default: PayPalCard }) => {
      ppCardInstance.value = new PayPalCard({
        completeHandle: _handleThreeDSComplete,
        errorHandle: _handleThreeDSError
      })
    })
  }

  function setPrePayCheckoutInfo (params = {}) {
    const { tokenList: list = [], selectedTokenPayInfo: tokenInfo = {}, ddcInfo: info, necessaryOrderInfo: order = {} } = params || {}
    tokenList.value = list || []
    selectedTokenPayInfo.value = tokenInfo || {}
    ddcInfo.value.jwt = info?.jwt || ''
    ddcInfo.value.formActionUrl = info?.formActionUrl || ''
    necessaryOrderInfo.value = order || {}
  }

  async function handlePayForPreCheckout ({
    params = {},
    onPayComplete = noop
  } = {}) {
    tokenPayConfig.value.onPayComplete = onPayComplete

    const {
      billno = '',
      payment_method = '',
      routeId = '',
      card_bin,
      last_four_no,
      countryCode = '',
      sessionId = '', // ddc sessionId
      installments, // 分期数
      expire_month = '', // 过期月份 
      expire_year = '', // 过期年份
      cardno = '', // 卡号
      cvv = '', // cvv
      cpfNumber = '', // cpf
      cardHolderName = '', // 持卡人姓名
      rememberCard = 0, // 是否记住卡
      paymentCode = '', // 具体路由到的支付方式
      cardType = '',
      freezeParamsStr = '', // 卡bin冻结参数
      autoBindCard = '' // 用户未主动选择了记住卡号
    } = params

    for (const key in params) {
      if (set) {
        set(tokenPayParams.value, key, params[key])
      } else {
        tokenPayParams.value[key] = params[key]
      }
    }

    const productType = getProductType({ type: tokenPayConfig.value.checkoutType })

    debuggerLog('handlePayForPreCheckout----orgs-----', { params })

    const data = {
      billno,
      routeId: routeId || '',
      publicKeyId: '',
      cardBin: card_bin || '',
      cardLastFour: last_four_no,
      sessionId: sessionId || '',
      paymentCode: paymentCode,
      deviceFingerId: window.GB_cybersource_uuid || '',
      forterDeviceFingerprintID: window.forterDeviceId || '',
      riskifiedDeviceFingerprintID: window.riskifiedDeviceId || '',
      cpfNumber,
      cardHolderName,
      rememberCard,
      cardType,
      autoBindCard,
      challengeWindowSize: 'fullPage'
    }

    const { encryptValid, publicKeyObj, encryptObj } = await getPubKey({ countryCode })
    
    // 加密参数处理
    const formatEncryptData = encryptValid ? encryptParams({
      sourceData: {
        cardno,
        expire_month,
        expire_year,
        secure_code: cvv,
      },
      publicKeyObj,
      encryptObj
    }) : {}

    Object.assign(data, formatEncryptData)

    if (installments) {
      data.installments = installments
    }
    if (freezeParamsStr) {
      data.freezeParamsStr = freezeParamsStr
    }

    data.publicKeyId = publicKeyObj?.pub_id

    if (!encryptValid) {
      console.error('encryptValid is false')
      apiReport.send({
        apiPath: '/pubkey/',
        payMethod: payment_method,
        siteuid: gbCommonInfo?.SiteUID,
        status_code: 0,
        response: '',
        billno,
      })
    }

    if (data.forterDeviceFingerprintID) {
      kafkaReport?.addTokenPayReport?.('forterDeviceFingerprintID', '风控数据采集正常')
    }
    kafkaReport?.addTokenPayReport?.('publicKeyObjKey', data.publicKeyId ? '加载加解密公钥正常' : '加解密公钥获取异常')
    if (adyenInstance.value || ppCardInstance.value) {
      kafkaReport?.addTokenPayReport?.('channelSdkOnLoad', '加载渠道js运行正常')
    }
    kafkaReport?.addTokenPayReport?.('encryptValid', encryptValid ? '卡号加密成功' : '卡号加密失败')

    if(window.forterSDKLoaded && !window.forterDeviceId){ //js初始化成功，且在发起支付时，前端给后端的指纹id为空
      apiReport.report({
        apiPath: 'forter/before-payment/error',
        siteuid: gbCommonInfo?.SiteUID,
        scene: 'js sdk init success but forter deviceId is null',
        billno: billno,
        paymentMethod: payment_method
      })
    }
    if(productType != 'gift_card' && !window.GB_cybersource_uuid) { // 非礼品卡，发起支付时，前端给后端的指纹id为空
      apiReport.report({
        apiPath: 'cybs/before-payment/error',
        siteuid: gbCommonInfo?.SiteUID,
        scene: 'cybs deviceId is null',
        billno: billno,
        paymentMethod: payment_method
      })
    }
    if(window.isRiskifiedLoaded && !window.riskifiedDeviceId) { // 发起支付时，前端给后端的指纹id为空
      apiReport.report({
        apiPath: 'riskified/before-payment/error',
        siteuid: gbCommonInfo?.SiteUID,
        scene: 'risk deviceId is null',
        billno: billno,
        paymentMethod: payment_method
      })
    }

    // 同盾指纹
    if (window._fmOpt?.__blackbox) {
      data.blackbox = window._fmOpt.__blackbox
    } else {
      // 未获取到同盾指纹上报
      apiReport.report({
        apiPath: 'tongdun/before-payment/error',
        sdk_init_status: window._fmOpt?.load_success ? 1 : 0, //同盾sdk初始化状态  1: 成功  0: 失败
        billno: billno,
        paymentMethod: payment_method
      })
    }

    debuggerLog('handleTokenPay--data-----', data)

    postTokenUnifiedPay(data)
      .then(res => {
        // res = ppcJsonMock
        const { status } = unHandleResult(res) || {}
        debuggerLog('unHandleResult--status-----', status)
        debuggerLog('postTokenUnifiedPay--result-----', res)
        if (status) {
          tokenPayConfig.value.onPayComplete?.({ status, result: res })
        }
      }).catch(err => {
        tokenPayConfig.value.onPayComplete?.({ status: 'fail', result: err })
        kafkaReport?.sendData?.({ resStatus: err?.status || '-1' })
      }).finally(() => {
        // setTokenPayCvv('') // 清空cvv
      })
  }

  async function normalTokenPayResultHandle ({ status, res } = {}) {
    const productType = getProductType({ type: tokenPayConfig.value.checkoutType })
    debuggerLog('normalTokenPayResultHandle--', { productType, status, res })
    tokenPayConfig.value?.toggleLoadingStatus?.(false)
    return { status, res }
  }

  function unFilterUnPayData(data = {}, options = {}) {
    const { checkoutType = '' } = options || {}
    const { langPath, host } = gbCommonInfo
  
    const checkoutAgainUrlMap = getCheckoutAgainUrl({ billno: data.billno, checkoutType })
  
    let cancelUrl = checkoutAgainUrlMap['pwa']
    let callbackUrl = `${host}${langPath}/ltspc/pay/result/unifiedCb?bill_no=${data.billno}`
    callbackUrl = mergeQueryString({
      url: callbackUrl,
      mergeObj: {
        isFrontToken: 1
      }
    })

    const failureUrl = callbackUrl
    const pendingUrl = callbackUrl
  
    const formData = {
      billno: data.billno || '',
      publicKeyId: data.publicKeyId || '',
      sessionId: data.sessionId || '',
      tokenId: data.tokenId || '',
      cardBin: data.cardBin || '',
      cardLastFour: data.cardLastFour || '',
      deviceFingerId: data.deviceFingerId || '',
      challengeWindowSize: data.challengeWindowSize || '',
      forterDeviceFingerprintID: data.forterDeviceFingerprintID || '',
      riskifiedDeviceFingerprintID: data.riskifiedDeviceFingerprintID || '',
      paymentCode: data.paymentCode || '',
      cvvHash: data.cvvHash || '',
      cvvCheck: data.cvvCheck || '',
      GB_cybs_loaded: window.GB_cybs_loaded ? 1 : 0,
      callbackUrl,
      cancelUrl,
      pendingUrl,
      failureUrl,
      device_language: navigator.language || navigator.browserLanguage || '',
      neurPayId: data.billno + '_' + Math.floor(Math.random() * 99999),
      neurStep: 1,
      routeId: data.routeId || '',
      cardNoHash: data.cardNoHash || '',
      cardExpireMonthHash: data.cardExpireMonthHash || '',
      cardExpireYearHash: data.cardExpireYearHash || '',
      isExpiredCard: data.isExpiredCard || 'FALSE',
      rememberCard: data.rememberCard ? 1 : 0,
      cpfNumber: data.cpfNumber || '',
      cardHolderName: data.cardHolderName || '',
      cardType: data.cardType || '',
      autoBindCard: data.autoBindCard || '',
      // 自定义参数，用于区分不同的token支付场景
      checkoutScene: 'front_pre_pay',
      ...getDeviceInfo()
    }

    if (data.installments) {
      formData.installments = data.installments
    }
    if (data.freezeParamsStr) {
      formData.freezeParamsStr = data.freezeParamsStr
    }

    return formData
  }

  async function postTokenUnifiedPay(data) {
    const checkoutType = tokenPayConfig.value?.checkoutType
    const formData = unFilterUnPayData(data, { checkoutType })
    const url = '/ltspc/pay/unPay/unifiedPay'

    kafkaReport?.updateKafkaData?.({
      neurStep: formData.neurStep,
      neurPayId: formData.neurPayId,
      startTime: +new Date(),
      callUrl: url,
      billno: formData.billno,
      paymentMethod: formData.paymentCode,
    })

    return schttp({
      method: 'post',
      url,
      data: formData
    })
  }

  function _handleMessageChange (event) {
    const types = ['challenge', 'normal', 'ocpResult']
    debuggerLog('event---message----', event.data)
    if (event.origin == location.origin && event.data) {
      try {
        var data =
            typeof event.data === 'string'
              ? JSON.parse(event.data)
              : event.data || {}
        if (
          data !== undefined &&
            data.app === 'shein' &&
            types.includes(data.type)
        ) {
          const result = typeof data.result === 'string' ? JSON.parse(data.result) : data.result || {}
          debuggerLog('message---result---', result)
          challengeModalOptions.wpChallengeShow = false
          const { status } = unHandleResult(result) || {}
          debuggerLog('unHandleResult--result--status-----', status)
          if (status) {
            tokenPayConfig.value.onPayComplete?.({ status, result })
            window.removeEventListener('message', _handleMessageChange, false)
          }
        }
      } catch (e) {
        debuggerLog('postMessage error: ' + e)
      }
    }
  }

  function unHandleResult(res) {
    debuggerLog('unHandleResult--', res)
    kafkaReport?.sendData?.({ res, resStatus: 200 })

    //只处理公用action
    if (res && res.code == 0 && res.info) {
      const {
        action,
        paramList,
        actionUrl,
        result,
        paymentCode,
        show_error_msg,
        isIndepenDsThreed,
        independThreedTraceID = '',
        pending
      } = res.info

      //跳转支付
      if (action == 'redirect') {
        window.location.href = actionUrl
        return
      } else if (action == 'render' && paramList) {
        if (isIndepenDsThreed == 1) {
          // 触发独立3ds挑战
          unifiedPayChallengeInfo.value.independThreedTraceID = independThreedTraceID || ''
        }
        if (paymentCode == 'worldpay-cardjs3ds') {
          const { jwt, md } = paramList
          if (jwt && md) {
            paramList.JWT = jwt
            paramList.MD = md
            delete paramList.jwt
            delete paramList.md
            challengeModalOptions.wpChallengeShow = true
            window.addEventListener('message', _handleMessageChange, false)
            _showWpChallenge(actionUrl, paramList)
            return
          }
        }
        if (paymentCode == 'alipay-card3ds') {
          unifiedPayChallengeInfo.value.gatewayPayNo = res.info.gatewayPayNo
          unifiedPayChallengeInfo.value.paymentCode = res.info.paymentCode
          // 支付宝3ds挑战
          useAlipayCardThreeDs({
            paramList,
            completeHandle: _handleThreeDSComplete
          })
          return true
        }
        
        if (isIndepenDsThreed == 1 && paramList.TermUrl) {
          // 触发独立3ds 1.0 挑战
          let termurl = paramList.TermUrl
          paramList.TermUrl =
            termurl.indexOf('?') > -1
              ? `${termurl}&independThreedTraceID=${independThreedTraceID}`
              : `${termurl}?independThreedTraceID=${independThreedTraceID}`
        }

        // window.verifyResultBiSend && verifyResultBiSend(true)
        makeForm(paramList, actionUrl)
        return
      } else if (action == 'challenge' && paramList) {

        if (res.info?.neurData?.neurStep) {
          kafkaReport.updateKafkaData({ neurStep: +res.info.neurData.neurStep })
        }

        //挑战流程
        if (action == 'challenge') {
          // window.ocpEventCenter?.$emit?.('show-process-toast', false)
          if (
            paramList.fingerprintToken ||
            paramList?.['threeds2.fingerprintToken']
          ) {
            unifiedPayChallengeInfo.value.gatewayPayNo = res.info.gatewayPayNo
            unifiedPayChallengeInfo.value.paymentCode = res.info.paymentCode
            // window.ocpEventCenter?.$emit?.('show-global-loading', true)
            challengeModalOptions.adyenChallengeShow = true
            adyenInstance.value?.init3DSIS?.(
              paramList.fingerprintToken ||
                paramList?.['threeds2.fingerprintToken']
            )
          } else if (
            paramList.challengeToken ||
            paramList?.['threeds2.challengeToken']
          ) {
            debuggerLog('challengeToken', paramList.challengeToken)
            unifiedPayChallengeInfo.value.gatewayPayNo = res.info.gatewayPayNo
            unifiedPayChallengeInfo.value.paymentCode = res.info.paymentCode
            challengeModalOptions.adyenChallengeShow = true
            adyenInstance.value?.initChallenge?.(
              paramList.challengeToken ||
                paramList?.['threeds2.challengeToken']
            )
          } else if (res?.info.paymentCode == 'PayPal-card') {
            // payEventCenter.$emit('show-global-loading', true)
            // this.gatewayPayNo = paramList.gatewayPayNo
            unifiedPayChallengeInfo.value.gatewayPayNo = paramList.gatewayPayNo
            unifiedPayChallengeInfo.value.paymentCode = res.info.paymentCode
            // this.paymentCode = info.paymentCode
            ppCardInstance.value?.initPaypalDropIn?.({
              paramList,
              totalPriceAmount: necessaryOrderInfo.value?.totalPrice?.amount || '',
              email: UserInfoManager.getSync({ key: 'pwa_user_email', actionType: 'hooks/useTokenPay' }) || ''
            })
          }
          return
        }
      } else if (action == 'direct' && result == 1) {
        //直接返回支付结果
        return {
          status: 'success',
          result: res
        }
      } else if (action == 'direct' && result == 0 && pending == 1) {
        return {
          status: 'pending',
          result: res
        }
      } else if (action == 'direct' && result == 0 && show_error_msg) {
        //处理报错文案，如果有客服链接，将{0}替换成langPath以适应不同站点
        const reg = /\{0\}\/robot/
        if (reg.test(show_error_msg)) {
          res.info.show_error_msg = show_error_msg.replace(
            reg,
            gbCommonInfo?.langPath + '/robot'
          )
        }
      }
    }
    return {
      status: 'fail',
      result: res
    }
  }

  function _showWpChallenge(actionUrl, paramList) {
    nextTick(() => {
      let $iframe = $(
        '<iframe width="400" height="250" name="challenge"></iframe>'
      )
      $('.token-pay-challenge__iframe').empty()
      $('.token-pay-challenge__iframe').append($iframe)

      let $form = $(`
      <form id="challengeForm" name="challengeForm" method="POST" target="challenge" action="${actionUrl}">
        <input type="hidden" name="JWT" value="${paramList.JWT}" />
        <input type="hidden" name="MD" value="${paramList.MD}" />
        <input type="submit" value="Continue" style="display:none;" />
      </form>
      `)
      $('body').append($form)
      document.challengeForm.submit()
    })
  }

  function _handleThreeDSComplete({ step, ...specialData } = {}) {
    debuggerLog('handleThreeDSComplete--', { step, ...specialData })
    if (step === 'ChallengeShopper') {
      challengeModalOptions.adyenChallengeShow = false
      tokenPayConfig.value?.toggleLoadingStatus?.(true)
    }

    const data = {
      billno: tokenPayParams.value.billno,
      gatewayPayNo: unifiedPayChallengeInfo.value.gatewayPayNo,
      paymentCode: unifiedPayChallengeInfo.value.paymentCode,
      independThreedTraceID: unifiedPayChallengeInfo.value.independThreedTraceID || '',
      verificationStep: step || '',
      verificationResult: specialData?.verificationResult || '',
      independThreedsVerifyInfo: specialData?.independThreedsVerifyInfo || '',
      threeDVerifyResult: specialData?.threeDVerifyResult || '',
      neurPayId: kafkaReport.kafkaInfo?.neurPayId,
      neurStep: kafkaReport.kafkaInfo?.neurStep + 1
    }

    kafkaReport?.updateKafkaData?.({
      neurStep: data.neurStep,
      neurPayId: data.neurPayId,
      startTime: +new Date(),
      callUrl: '/ltspc/pay/unPay/complete'
    })

    postTokenUnPayComplete(data)
      .then(result => {
        // result = ppcrJsonMock
        debuggerLog('postTokenUnPayComplete--result-----', result)
        const { status } = unHandleResult(result) || {}
        debuggerLog('unHandleResult--result--status-----', status)
        if (status) {
          tokenPayConfig.value.onPayComplete?.({ status, result })
        }
      }).catch(err => {
        kafkaReport?.sendData?.({ resStatus: err?.status || '-1' })
        tokenPayConfig.value.onPayComplete?.({ status: 'fail', result: err })
      })
      // .finally(() => {
      //   this.updateLoadingStatus({ loading: false })
      //   window.ocpEventCenter?.$emit?.('show-process-toast', false)
      // })
  }

  function _handleThreeDSError(err) {
    debuggerLog('handleThreeDSError--', err)
    challengeModalOptions.adyenChallengeShow = false
    // this.updateLoadingStatus({ loading: false })
    // this.ocpPayFailHandle({})
    // _handleError(err)
    tokenPayConfig.value.onPayComplete?.({ status: 'fail', result: err || {} })
    return err
  }

  return {
    initPrePayForCheckoutConfig,
    setPrePayCheckoutInfo,
    handlePayForPreCheckout,
    normalTokenPayResultHandle
  }
}
